import { Box, Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import React, { FunctionComponent, useState, useEffect, SetStateAction, useRef } from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';
import uniqid from 'uniqid';

import { getFetcher } from 'helpers';

import { StyledInput } from './StyledInput';
import { StyledLabel } from './StyledLabel';
import { StyledSubtitle } from './StyledSubtitle';
import { updatePaymentParameters } from '../Configuration/actions/updatePaymentParameters';
import { SUMMARY_MAPPINGS } from '../Configuration/constants';
import { getInitialParametersFromSession } from '../Configuration/helpers';
import {
	useClientBranding,
	useConfiguration,
	useGetIsQuickPayPage,
	usePaymentParameters,
} from '../Configuration/hooks';
import { RecurrentPrice } from '../Configuration/types';
import { roundToPrecision } from '../Payment/helpers';
import { usePaymentContext } from '../Payment/PaymentContext';
import { mapPaymentParameterLabels } from '../Payment/PaymentDetails/helpers';
import { useSetQuickPayConfig, useSetInputValue } from '../QuickPay/hooks';
import { CurrencyInput } from '../QuickPay/inputs/currency/CurrencyInput';
import { InitialParameters, Input } from '../QuickPay/types/types';

const ONE_TIME = 'one_time';
const RECURRING = 'recurring';

const STRIPE_AMOUNT_MODIFIER = 100;

export const RecurrentInputs: FunctionComponent<{
	amountInput?: Input;
	revenueCode?: string;
	areErrorsVisible?: boolean;
	setShowRecurrentInputsScreen?: React.Dispatch<SetStateAction<boolean | undefined>>;
}> = ({ amountInput, revenueCode, areErrorsVisible, setShowRecurrentInputsScreen }): JSX.Element => {
	const {
		data: {
			parameters: { payments },
		},
		config: { clientID, entity, recurrentPrices },
	} = useConfiguration();
	const intl = useIntl();
	const isQuickPayPage = useGetIsQuickPayPage();
	const setInputValue = useSetInputValue();
	const [selectedAmount, setSelectedAmount] = useState(0);
	const [otherAmountIsSelected, setOtherAmountIsSelected] = useState(false);
	const [selectedRecurrence, setSelectedRecurrence] = useState(ONE_TIME);
	const [selectedFrequency, setSelectedFrequency] = useState('');
	const setQuickPayConfig = useSetQuickPayConfig();
	const configuration = useConfiguration();
	const [showFrequencySelection, setShowFrequencySelection] = useState(false);
	const [showAmountOptions, setShowAmountOptions] = useState(true);
	const { paymentData, updatePayment } = usePaymentContext();
	const { paymentSummaryLabelMappings, backButton } = useClientBranding();
	const paymentParameters = usePaymentParameters();
	const customParamsLabelMapping = mapPaymentParameterLabels(paymentSummaryLabelMappings || {});
	const sessionData = getInitialParametersFromSession<InitialParameters>();
	const normalisedSelectedAmount = roundToPrecision(selectedAmount / STRIPE_AMOUNT_MODIFIER, 2);

	const { data: recurrentPricesForRevenueCode } = useSWR<RecurrentPrice[]>(
		revenueCode
			? `/configuration/recurrent-prices?revenueCode=${revenueCode}&clientId=${clientID}&entity=${entity}`
			: null,
		getFetcher,
		{ revalidateOnFocus: false },
	);

	const amountOptionsXPay = revenueCode
		? recurrentPrices?.filter((price) => price.revenueCode === revenueCode)
		: recurrentPrices;
	const revenueCodeOrFirst = revenueCode || recurrentPrices?.[0].revenueCode;
	const amountOptions = isQuickPayPage ? recurrentPricesForRevenueCode : amountOptionsXPay;
	const customAmount: React.MutableRefObject<Partial<RecurrentPrice> | undefined> = useRef(
		amountOptions?.find((price) => price.customAmountFormat),
	);

	const [showCustomAmount, setShowCustomAmount] = useState(!!customAmount.current);

	const frequencyOptions = amountOptions?.find((price) => price.unitAmount === selectedAmount)?.recurrenceOptions;

	const paymentLine = payments?.find((payment) => payment.revenueCode === revenueCodeOrFirst);

	useEffect(() => {
		if (selectedAmount === 0) {
			const urlAmount = isQuickPayPage
				? sessionData && sessionData.amount
					? Number(sessionData.amount) * STRIPE_AMOUNT_MODIFIER
					: undefined
				: paymentLine?.amount
					? paymentLine?.amount * STRIPE_AMOUNT_MODIFIER
					: undefined;
			if (urlAmount) {
				const preselectedAmount = amountOptions?.find(
					(price) => !price.customAmountFormat && price.unitAmount === urlAmount,
				);
				if (preselectedAmount) {
					setSelectedAmount(preselectedAmount.unitAmount);
					if (!isQuickPayPage) {
						setShowCustomAmount(false);
					}
				} else {
					setSelectedAmount(urlAmount);
					setOtherAmountIsSelected(true);
					customAmount.current = Object.assign(customAmount.current || {}, { unitAmount: urlAmount });
					if (isQuickPayPage) {
						setShowCustomAmount(true);
					}
				}
			} else {
				setSelectedAmount(amountOptions?.find((price) => !price.customAmountFormat)?.unitAmount || 0);
			}
		}
		if (amountOptions) {
			setShowAmountOptions(amountOptions?.length > 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// side effects for quickpay configs
		if (setQuickPayConfig && isQuickPayPage && amountInput) {
			const { inputs } = configuration.config;
			if (inputs) {
				const updatedInputs = inputs.map((input) => {
					const isRevenueCodeWithRecurrentData =
						input.summaryMapping === SUMMARY_MAPPINGS.REVENUE_CODE && selectedRecurrence === RECURRING;

					return Object.assign(input, {
						recurrenceData: isRevenueCodeWithRecurrentData
							? {
									selectedAmount: otherAmountIsSelected
										? (typeof amountInput.value === 'string' ? Number(amountInput.value) : amountInput.value || 0) *
											STRIPE_AMOUNT_MODIFIER
										: selectedAmount,
									selectedRecurrence,
									price: selectedFrequency,
									format: frequencyOptions?.find((frequencyOption) => frequencyOption.priceId === selectedFrequency)
										?.format,
									product: amountOptions?.find((price) => price.revenueCode === revenueCode)?.product,
									isCustomAmount: otherAmountIsSelected,
									fixedBillingDate: frequencyOptions?.find(
										(frequencyOption) => frequencyOption.priceId === selectedFrequency,
									)?.fixedBillingDate,
								}
							: null,
					});
				});
				setQuickPayConfig(Object.assign(configuration, { inputs: updatedInputs }));
			}
		}
	}, [
		revenueCode,
		frequencyOptions,
		selectedAmount,
		selectedFrequency,
		selectedRecurrence,
		setQuickPayConfig,
		configuration,
		isQuickPayPage,
		amountInput,
		amountOptions,
		otherAmountIsSelected,
	]);

	useEffect(() => {
		if (!isQuickPayPage && selectedAmount !== 0) {
			updatePayment({ ...paymentData, amount: normalisedSelectedAmount });
		}
		if (frequencyOptions && frequencyOptions?.length > 0) {
			setSelectedFrequency(frequencyOptions?.[0].priceId || '');
		}
		if (setInputValue && amountInput && isQuickPayPage) {
			setInputValue(amountInput.id, normalisedSelectedAmount);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAmount, normalisedSelectedAmount, isQuickPayPage, amountInput, setInputValue]);

	const handleAmountChange = (_event: React.MouseEvent<HTMLElement>, newAmount: number | null) => {
		if (newAmount === null) {
			return;
		}
		setSelectedAmount(newAmount);
		if (isQuickPayPage) {
			setOtherAmountIsSelected(
				Boolean(amountOptions?.find((option) => option.unitAmount === newAmount)?.isCustomAmount),
			);
		}
	};

	const handleRecurrenceChange = (_event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
		if (newValue === null) {
			return;
		}
		setSelectedRecurrence(newValue);
		setShowFrequencySelection(newValue === RECURRING);
	};

	const handleFrequencyChange = (_event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
		if (newValue === null) {
			return;
		}
		setSelectedFrequency(newValue);
	};

	const today = new Date();
	const startingToday = `${today.toLocaleString(undefined, { day: '2-digit' })} ${today.toLocaleString(undefined, {
		month: 'long',
	})}, ${today.toLocaleString(undefined, { year: 'numeric' })}`;

	const referenceNumberDescription =
		payments?.find((payment) => payment.revenueCode === revenueCodeOrFirst)?.referenceNumberDescription || '';

	const referenceNumber =
		payments?.find((payment) => payment.revenueCode === revenueCodeOrFirst)?.referenceNumber || '';

	const handleSubscribe = () => {
		if (!isQuickPayPage) {
			const updatedPayments = configuration.data.parameters.payments?.map((payment) => {
				const price = recurrentPrices?.find((price) => price.revenueCode === payment.revenueCode);
				if (price && selectedRecurrence === RECURRING && selectedAmount !== 0 && selectedFrequency) {
					return Object.assign(payment, {
						amount: normalisedSelectedAmount,
						recurrenceData: {
							selectedAmount,
							selectedRecurrence,
							price: selectedFrequency,
							product: recurrentPrices?.[0].product,
							isCustomAmount: otherAmountIsSelected,
							format: frequencyOptions?.find((frequencyOption) => frequencyOption.priceId === selectedFrequency)
								?.format,
							fixedBillingDate: frequencyOptions?.find(
								(frequencyOption) => frequencyOption.priceId === selectedFrequency,
							)?.fixedBillingDate,
						},
					});
				}
				return payment;
			});

			if (updatedPayments) {
				updatePaymentParameters(configuration.data.identifier, updatedPayments, isQuickPayPage)();
			}

			if (setShowRecurrentInputsScreen) {
				setShowRecurrentInputsScreen(false);
			}
		}
	};

	const handleBack = () => {
		if (paymentParameters.backUrl) {
			window.location.replace(paymentParameters.backUrl);
		}
	};

	return (
		<Box
			p={!isQuickPayPage ? 4 : 0}
			sx={(theme) => ({
				'& .MuiToggleButton-root': {
					color: theme.palette.text.primary,
				},
				'& .MuiToggleButton-root.Mui-selected': {
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.getContrastText(theme.palette.secondary.main),
				},
				'& .MuiToggleButton-root.Mui-selected:hover': {
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.getContrastText(theme.palette.secondary.main),
				},
			})}
		>
			{!isQuickPayPage && (
				<>
					<Box sx={(theme) => ({ margin: theme.spacing(3, 1) })}>
						<StyledLabel>{customParamsLabelMapping.referenceNumberDescription}</StyledLabel>
						<StyledInput value={referenceNumberDescription || ''} disabled />
					</Box>
					<Box sx={(theme) => ({ margin: theme.spacing(3, 1) })}>
						<StyledLabel>{customParamsLabelMapping.referenceNumber}</StyledLabel>
						<StyledInput value={referenceNumber || ''} disabled />
					</Box>
				</>
			)}
			<Box sx={(theme) => ({ margin: theme.spacing(3, 1) })}>
				<StyledSubtitle variant="subtitle1" color="primary">
					{intl.formatMessage({ id: 'recurrent-payments_recurrence' })}
				</StyledSubtitle>
				<ToggleButtonGroup
					value={selectedRecurrence}
					exclusive
					onChange={handleRecurrenceChange}
					aria-label="frequency selection"
					size="large"
				>
					<ToggleButton value={ONE_TIME} key="one_time">
						{intl.formatMessage({ id: 'recurrent-payments_one-time' })}
					</ToggleButton>
					<ToggleButton value={RECURRING} key="recurring">
						{intl.formatMessage({ id: 'recurrent-payments_recurring' })}
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			<Box sx={(theme) => ({ margin: theme.spacing(1, 1) })}>
				<StyledSubtitle variant="subtitle1" color="primary">
					{intl.formatMessage({ id: 'recurrent-payments_amount' })}
				</StyledSubtitle>
			</Box>
			{showAmountOptions && (
				<Box
					sx={(theme) => ({
						marginBottom: theme.spacing(4),
						marginLeft: theme.spacing(1),
						marginRight: theme.spacing(1),
					})}
				>
					<ToggleButtonGroup
						value={selectedAmount}
						exclusive
						onChange={handleAmountChange}
						aria-label="amount selection"
					>
						{amountOptions
							?.filter((price) => !price.customAmountFormat)
							.map((price) => (
								<ToggleButton value={price.unitAmount} key={uniqid()}>
									{price.amount}
								</ToggleButton>
							))}
						{showCustomAmount && (
							<ToggleButton value={customAmount?.current?.unitAmount || 0} key={uniqid()}>
								{intl.formatMessage({ id: 'recurrent-payments_custom-amount' })}
							</ToggleButton>
						)}
					</ToggleButtonGroup>
				</Box>
			)}
			<Box sx={(theme) => ({ margin: theme.spacing(2, 1) })}>
				{amountInput && isQuickPayPage && (
					<CurrencyInput
						input={amountInput}
						areErrorsVisible={areErrorsVisible || false}
						hideLabel
						isDisabled={!otherAmountIsSelected}
						fixedPaymentAmountIsEnabled={false}
					/>
				)}
				{!isQuickPayPage && (
					<CurrencyInput
						input={{
							id: 'xpayAmountInput',
							helperText: {},
							value: normalisedSelectedAmount,
						}}
						fixedPaymentAmountIsEnabled={false}
						areErrorsVisible={false}
						hideLabel
						isDisabled
					/>
				)}
			</Box>
			{showFrequencySelection && (
				<Box sx={(theme) => ({ margin: theme.spacing(2, 1) })}>
					<StyledSubtitle variant="subtitle1" color="primary">
						{intl.formatMessage({ id: 'recurrent-payments_frequency' })}
					</StyledSubtitle>
					<ToggleButtonGroup
						value={selectedFrequency}
						exclusive
						onChange={handleFrequencyChange}
						aria-label="frequency selection"
					>
						{frequencyOptions?.map((recurrence) => (
							<ToggleButton value={recurrence.priceId} key={uniqid()}>
								{recurrence.name}
							</ToggleButton>
						))}
					</ToggleButtonGroup>
					<Box sx={(theme) => ({ margin: theme.spacing(1, 0), color: theme.palette.text.secondary })}>
						<Box>
							{intl.formatMessage({ id: 'recurrent-payments_starting-today' })} {startingToday}
						</Box>
					</Box>
				</Box>
			)}
			<Box
				sx={(theme) => ({
					margin: theme.spacing(4, 1),
					display: 'flex',
					justifyContent: 'space-between',
					'& button': {
						width: 'auto',
					},
				})}
			>
				{paymentParameters.backUrl && paymentParameters.backUrl.length && (
					<Button
						size="large"
						variant="contained"
						color="primary"
						style={{ backgroundColor: 'transparent' }}
						onClick={handleBack}
						aria-label={backButton ? backButton.text : intl.formatMessage({ id: 'button__back' })}
						sx={(theme) => ({
							textTransform: 'none',
							color: theme.palette.secondary.main,
							border: 'none',
							boxShadow: 'none',

							'&:hover': {
								boxShadow: 'none',
							},
						})}
					>
						{backButton ? backButton.text : intl.formatMessage({ id: 'button__back' })}
					</Button>
				)}
				{!isQuickPayPage && (
					<Button size="large" variant="contained" color="secondary" onClick={handleSubscribe} aria-label="Subscribe">
						{intl.formatMessage({ id: 'recurrent-payments_continue-button' })}
					</Button>
				)}
			</Box>
		</Box>
	);
};
